import React, { useMemo } from 'react';
import ITodoList from 'src/common/interfaces/Dto/Todo/TodoList/ITodoList';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import Card from './components/Card/Card';
import useStyles from './Section.style';

function SectionContent({ todoList }: { todoList: ITodoList }) {
  const classes = useStyles();
  const { todoItems } = todoList;
  return (
    <div className={classes.cardContainer}>
      {
        useMemo(() => todoItems.map((todoItem, index) => (
          <Draggable
            key={`${todoList.id}-${todoItem.todoItemId}`}
            draggableId={todoItem.todoItemId.toString()}
            index={index}
          >
            {(provided: DraggableProvided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                data-testid="taskItem"
              >
                <Card todoItem={todoItem} />
              </div>
            )}
          </Draggable>
        )), [todoItems])
      }
    </div>
  );
}

export default SectionContent;
