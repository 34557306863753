/* eslint-disable no-param-reassign */
import React, { useContext, memo } from 'react';
import { Button, TextField } from '@material-ui/core';
import { Field, Form, Formik, FormikErrors, FormikProps, FormikState } from 'formik';
import * as Yup from 'yup';
import * as apiUtils from 'src/utils/apiUtils';
import { CompanyContext } from 'src/context/CompanyContext';
import IEditCompanyInfo from 'src/common/interfaces/IEditCompanyInfo';
import { StatusCodes } from 'http-status-codes';
import useStyles from './EditCompanyInfoForm.style';

interface IProps {
  formikRef: React.RefObject<FormikProps<IEditCompanyInfo>>;
}
function EditCompanyInfoForm({ formikRef }: IProps) {
  const classes = useStyles();
  const {
    setEditPanelOpen,
    editCompanyInfo,
    setEditCompanyInfo,
    companyId,
    companyName,
    setCompanyName,
    companyDescription,
    setCompanyDescription,
  } = useContext(CompanyContext);

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
  });

  const handleOnSubmit = async (
    values: IEditCompanyInfo,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<IEditCompanyInfo>) => void,
    resetForm: (nextState?: Partial<FormikState<IEditCompanyInfo>>) => void,
  ) => {
    const { name } = values;
    let { description } = values;
    if (description === '') {
      description = companyDescription;
    }
    try {
      const { status } = await apiUtils.updateCompanyProfile({
        companyId,
        name: name.trim(),
        description,
      });
      if (status === StatusCodes.OK) {
        setSubmitting(false);
        setCompanyName(name);
        setCompanyDescription(description);
        setEditPanelOpen(false);
        setEditCompanyInfo({ ...editCompanyInfo, companyId: '', name: '', description: '' });
        resetForm();
      }
    } catch (error: any) {
      const errorArgs = { name: '', description: '' };
      if (error.response) {
        if (error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
          setSubmitting(false);
          setErrors({ ...errorArgs, name: 'Internal server problem, please try again!' });
        }
        if (error.response.status >= StatusCodes.BAD_REQUEST && error.response.status < StatusCodes.INTERNAL_SERVER_ERROR) {
          setSubmitting(false);
          setErrors({ ...errorArgs, name: 'CompanyID not Existed' });
        }
      }
      if (!error.response) {
        setSubmitting(false);
        setErrors({ ...errorArgs, name: 'Internal server problem, please try again!' });
      }
    }
  };

  const handleFormChange = (
    dataType: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(dataType, e.target.value);
    setEditCompanyInfo({ ...editCompanyInfo, [dataType]: e.target.value });
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        companyId: '',
        name: companyName,
        description: companyDescription,
      }}
      onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
        handleOnSubmit(values, setSubmitting, setErrors, resetForm);
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, isSubmitting, isValid, setFieldValue }) => (
        <Form>
          <Field
            as={TextField}
            name="name"
            value={values.name}
            className={classes.bottom_card_name}
            label="Company Name:"
            onChange={handleFormChange('name', setFieldValue)}
            helperText={errors.name}
            variant="standard"
            aria-label="name"
          />
          <Field
            as={TextField}
            className={classes.bottom_card_name}
            label="Company Description: (optional)"
            variant="standard"
            name="companyDescription"
            aria-label="companyDescription"
            value={values.description}
            onChange={handleFormChange('description', setFieldValue)}
          />
          {editCompanyInfo && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              aria-label="submit-button"
              className={classes.submit_button}
              disabled={isSubmitting || editCompanyInfo.name === '' || !isValid}
            >
              {isSubmitting ? 'Sending...' : 'Send Now'}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
}
export default memo(EditCompanyInfoForm);
