import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { OTHER_ERROR } from 'src/common/constants/ErrorMessages';
import * as apiUtils from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import IResetPasswordForm from 'src/common/interfaces/IResetPasswordForm';
import * as Yup from 'yup';

const usePasswordResetForm = (code:string | null) => {
  const initialValues: IResetPasswordForm = {
    password: '',
    confirmPassword: '',
  };
  const history = useHistory();

  const validationPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=\S*[a-zA-Z])(?=\S*[0-9#!"$%&'()*+,-./:;<=>?@[\]^_`{|}~])\S{8,}$/,
        'Password is invalid! At least 8 characters with 1 alphabet and 1 non-letter character',
      )
      .max(128, 'Your password is too long!')
      .required('Password required!'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords do not match')
      .required('Please confirm your password'),
  });

  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [opened, setOpen] = useState(false);

  const handleOnSubmit = async ({ password } : IResetPasswordForm) => {
    setWarning({
      shown: false,
      message: '',
    });
    try {
      const resetResponse = await apiUtils.resetPassword({ code, password });
      if (resetResponse.status === StatusCodes.OK) {
        setOpen(true);
      }
    } catch (err: any) {
      if (err.response) {
        if (err.response.status === StatusCodes.CONFLICT || err.response.status === StatusCodes.NOT_FOUND) {
          setWarning({
            shown: true,
            message: 'Your email does not exist or be activated.',
          });
        }
      } else {
        setWarning({
          shown: true,
          message: OTHER_ERROR,
        });
      }
    }
  };
  useEffect(() => {
    let isSubscribed = true;
    localStorage.clear();
    if (code === null) {
      history.push('/login');
    }
    const getCodeStatus = async () => {
      try {
        await apiUtils.getResetterInfo(code);
      } catch (err: any) {
        if (isSubscribed && err.response.status !== StatusCodes.OK) {
          history.push('/login');
        }
      }
    };
    getCodeStatus();
    return () => { isSubscribed = false; };
  }, []);
  return { initialValues, validationPasswordSchema, handleOnSubmit, warning, opened, setOpen, history };
};

export default usePasswordResetForm;
