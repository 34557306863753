/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import { Button, TextField } from '@material-ui/core';
import { Field, Form, Formik, FormikErrors, FormikProps, FormikState } from 'formik';
import * as Yup from 'yup';
import * as apiUtils from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import { CompanyContext } from 'src/context/CompanyContext';
import IInviteMember from 'src/common/interfaces/IInviteMember';
import useStyles from './InviteForm.style';

interface IProps {
  formikRef: React.RefObject<FormikProps<IInviteMember>>
}
function InviteForm({ formikRef }:IProps) {
  const classes = useStyles();
  const { companyEmployees, setIfOpenInviteMemberPanel, inviteMemberInfo, setInviteMemberInfo, companyId } = useContext(CompanyContext);
  const validationSchema = Yup.object({
    email: Yup.string().email('Enter a valid email').required('Email is required'),
    name: Yup
      .string()
      .required('Name is required'),
  });

  const validateEmail = (email:string) => {
    let error;
    if (companyEmployees.filter((employee) => employee.email.toString() === email).length === 1) {
      error = 'Already joined in this company';
    }
    return error;
  };
  const handleOnSubmit = async (
    values:IInviteMember,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors:(errors: FormikErrors<IInviteMember>) => void,
    resetForm:(nextState?: Partial<FormikState<IInviteMember>>) => void,
  ) => {
    const { name, email, title } = values;
    try {
      const res = await apiUtils.sendCompanyInvitation(companyId, name, email, title);
      if (res.status === StatusCodes.OK) {
        setSubmitting(false);
        setIfOpenInviteMemberPanel(false);
        setInviteMemberInfo({ ...inviteMemberInfo, name: '', email: '', title: '' });
        resetForm();
      }
    } catch (error:any) {
      const errorArgs = { name: '', email: '', title: '' };
      if (error.response) {
        if (error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
          setSubmitting(false);
          setErrors({ ...errorArgs, email: 'Internal server problem, please try again!' });
        }
        if (error.response.status >= StatusCodes.BAD_REQUEST && error.response.status < StatusCodes.INTERNAL_SERVER_ERROR) {
          setSubmitting(false);
          setErrors({ ...errorArgs, email: 'User not Existed' });
        }
      }
      if (!error.response) {
        setSubmitting(false);
        setErrors({ ...errorArgs, email: 'Internal server problem, please try again!' });
      }
    }
  };

  const handleFormChange = (dataType:string, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => (e:React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(dataType, e.target.value);
    setInviteMemberInfo({ ...inviteMemberInfo, [dataType]: e.target.value });
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        email: '',
        name: '',
        title: '',
      }}
      onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
        handleOnSubmit(values, setSubmitting, setErrors, resetForm);
      }}
      validationSchema={validationSchema}

    >
      {({ values, errors, isSubmitting, isValid, setFieldValue }) => (
        <Form>
          <Field
            as={TextField}
            name="name"
            value={values.name}
            className={classes.bottom_card_name}
            label="Name:"
            onChange={
              handleFormChange('name', setFieldValue)
            }
            helperText={errors.name}
            variant="standard"
            aria-label="name"
          />
          <Field
            as={TextField}
            className={classes.bottom_card_name}
            label="Email address:"
            name="email"
            value={values.email}
            aria-label="email"
            helperText={errors.email}
            variant="standard"
            validate={validateEmail}
            onChange={handleFormChange('email', setFieldValue)}
          />
          <Field
            as={TextField}
            className={classes.bottom_card_name}
            label="Title: (optional)"
            variant="standard"
            name="title"
            aria-label="title"
            value={values.title}
            onChange={handleFormChange('title', setFieldValue)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            aria-label="submit-button"
            className={classes.submit_button}
            disabled={isSubmitting || inviteMemberInfo.email === '' || !isValid || inviteMemberInfo.name === ''}
          >
            {isSubmitting ? 'Sending...' : 'Send Now'}
          </Button>

        </Form>
      )}
    </Formik>
  );
}
export default InviteForm;
