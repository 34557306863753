import React, { useContext, useEffect, useState } from 'react';
import compare from 'src/common/functions/compare';
import IProjectMember from 'src/common/interfaces/IProjectMember';
import { TodoContext } from 'src/context/TodoContext';
import useStyles from './AssigneeMenu.style';
import ProjectMember from './components/Assignee/ProjectMember';
import AssigneeForm from './components/AssigneeForm/AssigneeForm';

interface PropsType {
  projectMembers: IProjectMember[];
  assignees: IProjectMember[];
  assigneesIds: string;
  setNewTodoItem: Function;
  setIsAssigneesOpen: Function
}

function AssigneeMenu(props: PropsType) {
  const { projectMembers, assignees, assigneesIds, setNewTodoItem, setIsAssigneesOpen } = props;
  const [disabled, setdiabled] = useState(false);
  const { setUpdate } = useContext(TodoContext);
  const classes = useStyles();
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleClickInviteBtn = () => {
    setIsFormOpen(true);
  };

  const handleOnClickFromMembersList = async (id: string) => {
    let newAssigneesIds;
    if (assigneesIds && !assigneesIds.split(',').includes(id.toString())) {
      newAssigneesIds = assigneesIds.concat(',', id);
      if (newAssigneesIds.split(',').length > 5) { return; }
      await setNewTodoItem(undefined, undefined, newAssigneesIds);
      setUpdate((prevState) => prevState + 1);
    }
    if (!assigneesIds) {
      newAssigneesIds = id;
      await setNewTodoItem(undefined, undefined, newAssigneesIds);
      setUpdate((prevState) => prevState + 1);
    }
    setIsAssigneesOpen(false);
  };
  useEffect(() => {
    if (assignees.length >= 5) {
      setdiabled(true);
    }
  }, [assignees]);
  return (
    <div className={classes.assigneesMenu}>
      {projectMembers && !isFormOpen ? (
        <div className={classes.projectMembersList}>
          {assignees.sort(compare).map((member) => (
            <ProjectMember
              key={member.id}
              id={member.id}
              name={member.name}
              email={member.email}
              onClick={() => {}}
            />
          ))}
          <button type="button" className={classes.inviteBtn} onClick={handleClickInviteBtn} disabled={disabled}>
            <span className={classes.addIcon}>+</span>
            <span>Invite teammates </span>
          </button>
        </div>
      ) : (
        <AssigneeForm
          assignees={assignees}
          assigneesIds={assigneesIds}
          setNewTodoItem={setNewTodoItem}
          setIsAssigneesOpen={setIsAssigneesOpen}
          handleOnClickFromMembersList={handleOnClickFromMembersList}
        />
      )}
    </div>
  );
}

export default AssigneeMenu;
