import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ISignUp from 'src/common/interfaces/ISignUp';
import { ErrorMessage } from 'formik';
import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as apiUtils from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import notificationIcon from 'src/assets/svgs/notificationIcon.svg';
import { INTERNAL_SERVER_ERROR } from 'src/common/constants/ErrorMessages';
import useStyles from '../InvitationRegisterForm.style';

export const useRegisterForm = (code:string | null) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [signupError, setSignupError] = useState({
    shown: false,
    message: '',
  });

  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [invitationInfo, setInvitationInfo] = useState({
    companyId: '',
    companyName: '',
    title: '',
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter your name')
      .max(128, 'Your name input reaches 128 characters limit!'),
    email: Yup.string()
      .required('Please enter your email')
      .email('Invalid email address')
      .max(128, 'Your email address reaches 128 characters limit!'),
    password: Yup.string()
      .matches(
        /^(?=\S*[a-zA-Z])(?=\S*[0-9#!"$%&'()*+,-./:;<=>?@[\]^_`{|}~])\S{8,}$/,
        'Password is invalid! At least 8 characters with 1 alphabet and 1 non-letter character',
      )
      .max(128, 'Your password is too long!')
      .required('Password required!'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords do not match')
      .required('Please confirm your password'),
  });

  const fetchDataFromInvitationLink = async () => {
    localStorage.clear();
    try {
      const getInvitationInfoResponse = await apiUtils.getInvitationInfo(code);

      if (getInvitationInfoResponse.status === StatusCodes.OK) {
        setInvitationInfo({
          companyId: getInvitationInfoResponse.data.companyId.toString(),
          companyName: getInvitationInfoResponse.data.companyName,
          title: getInvitationInfoResponse.data.title,
        });
        setInitialValues({
          ...initialValues,
          name: getInvitationInfoResponse.data.name,
          email: getInvitationInfoResponse.data.email,
        });
      }
    } catch (error: any) {
      if (error.response && error.response.status !== StatusCodes.OK) {
        setOpen(true);
      }
    }
  };
  const { companyId, title, companyName } = invitationInfo;
  const handleOnSubmit = useCallback(async ({ name, email, password }:ISignUp) => {
    try {
      const signupResponse = await apiUtils.invitationsRegister({ name, email, password, companyId, title });
      if (signupResponse.status === StatusCodes.OK) {
        localStorage.setItem('name', signupResponse.data.name);
        localStorage.setItem('email', signupResponse.data.email);
        localStorage.setItem('userId', signupResponse.data.id);
        localStorage.setItem('companyId', companyId.toString());
        localStorage.setItem('accessToken', signupResponse.data.token);
        history.push(`/companies/${companyId}`);
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          setSignupError({
            shown: true,
            message: INTERNAL_SERVER_ERROR,
          });
        }
      }
    }
  }, [history, setSignupError, companyId, title]);
  useEffect(() => {
    if (code === null) {
      setOpen(true);
    } else {
      fetchDataFromInvitationLink();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { open, initialValues, validationSchema, handleOnSubmit, signupError, companyName };
};

export const useExtraFormConf = () => {
  const classes = useStyles();
  const getStartAdornment = useCallback((component: JSX.Element) => ({
    startAdornment: (
      <InputAdornment position="start">
        {component}
      </InputAdornment>
    ),
  }), []);

  const getErrorMsg = useCallback((name: string) => (
    <ErrorMessage name={name}>
      { (msg) => (
        <span className={classes.error}>
          <span className={classes.img}>
            <img src={notificationIcon} alt="notificationIcon" />
          </span>
          <span className={classes.errormessage}>{msg}</span>
        </span>
      )}
    </ErrorMessage>
  ), [classes.error, classes.errormessage, classes.img]);
  return { getStartAdornment, getErrorMsg };
};

export const useDoubleAdornment = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const getDoubleAdornment = useCallback((component: JSX.Element) => ({
    startAdornment: (
      <InputAdornment position="start">
        {component}
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={handleClickShowPassword}>
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  }), [showPassword]);

  const getSingleAdornment = useCallback((component: JSX.Element) => ({
    startAdornment: (
      <InputAdornment position="start">
        {component}
      </InputAdornment>
    ),
  }), [showPassword]);

  return { getDoubleAdornment, showPassword, getSingleAdornment };
};
