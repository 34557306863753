import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import * as apiUtils from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import ISignUp from 'src/common/interfaces/ISignUp';
import IVerifyEmailExists from 'src/common/interfaces/IVerifyEmailExists';
import { INTERNAL_SERVER_ERROR, EMAIL_EXISTS_ERROR, OTHER_ERROR } from 'src/common/constants/ErrorMessages';

const useSignupForm = () => {
  const [signupError, setSignupError] = useState({
    have: false,
    message: '',
    hasLink: false,
    linkContent: '',
  });
  const history = useHistory();
  const location = useLocation();
  const initialValues = {
    name: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
  };
  const validationNameAndEmailSchema = Yup.object().shape({
    name: Yup.string().trim().required('Your name required!').max(128, 'Your name input is too long!'),
    email: Yup.string()
      .trim()
      .required('Email required!')
      .email('Please enter a valid email address')
      .max(128, 'Your email address is too long!'),
    confirmEmail: Yup.string()
      .trim()
      .oneOf([Yup.ref('email')], 'Email addresses do not match')
      .required('Please confirm your email'),
  });
  const validationPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=\S*[a-zA-Z])(?=\S*[0-9#!"$%&'()*+,-./:;<=>?@[\]^_`{|}~])\S{8,}$/,
        'Password is invalid! At least 8 characters with 1 alphabet and 1 non-letter character',
      )
      .max(128, 'Your password is too long!')
      .required('Password required!'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords do not match')
      .required('Please confirm your password'),
  });
  const handleCheckEmailOnSubmit = useCallback(async ({ email }: IVerifyEmailExists) => {
    try {
      await apiUtils.verifyEmailExists({ email: email.trim() });
    } catch (error: any) {
      if (error.response) {
        // eslint-disable-next-line default-case
        switch (error.response.status) {
          case StatusCodes.INTERNAL_SERVER_ERROR:
            setSignupError({
              have: true,
              message: INTERNAL_SERVER_ERROR,
              hasLink: false,
              linkContent: '',
            });
            break;
          case StatusCodes.CONFLICT:
            setSignupError({
              have: true,
              message: EMAIL_EXISTS_ERROR,
              hasLink: true,
              linkContent: 'Sign in here',
            });
            break;
        }
      } else {
        setSignupError({
          have: true,
          message: OTHER_ERROR,
          hasLink: false,
          linkContent: '',
        });
      }
    }
  }, [setSignupError]);
  const handleCreateAccountOnSubmit = useCallback(async ({ name, email, password }: ISignUp) => {
    try {
      const signupResponse = await apiUtils.signup({ name: name.trim(), email: email.trim(), password });
      if (signupResponse.status === StatusCodes.OK) {
        localStorage.setItem('email', email.trim());
        history.push('/activation', { path: location.pathname });
      }
    } catch (error: any) {
      if (error.response && error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
        setSignupError({
          have: true,
          message: INTERNAL_SERVER_ERROR,
          hasLink: false,
          linkContent: '',
        });
      } else {
        setSignupError({
          have: true,
          message: OTHER_ERROR,
          hasLink: false,
          linkContent: '',
        });
      }
    }
  }, [history, location.pathname]);
  return { initialValues, validationNameAndEmailSchema, validationPasswordSchema, handleCheckEmailOnSubmit, handleCreateAccountOnSubmit, signupError };
};

export default useSignupForm;
