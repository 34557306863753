import React, { useState, useContext, useRef, useEffect, KeyboardEvent, ChangeEvent } from 'react';
import { useAuth } from 'src/common/customHooks';
import CardContainer from '@material-ui/core/Card';
import { TodoContext } from 'src/context/TodoContext';
import { createTodoItem, changeNewTodoItemCompletedStatus, updateTodoItem } from 'src/utils/apiUtils';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import IProjectMember from 'src/common/interfaces/IProjectMember';
import ITodoItem from 'src/common/interfaces/Dto/Todo/TodoItem/ITodoItem';
import PrioritySelector from 'src/components/PrioritySelector/PrioritySelector';
import { StatusCodes } from 'http-status-codes';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Assignees from './components/Assignees/Assignees';
import Calendar from './components/Calendar/Calendar';
import useStyles from './Card.styles';

function Card({ todoItem }: { todoItem: ITodoItem }) {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>();
  const createdUserId = localStorage.getItem('userId') as string;
  const {
    description,
    priority,
    subscribersIds,
    dueDate,
    completed,
    todoItemId,
    companyId,
    projectId,
    todoListId,
  } = todoItem;
  const { setAddingTodoItem, setUpdate, projectMembers, todoLists, setTodoLists, projectInfo } = useContext(TodoContext);
  const { doneListId } = projectInfo;
  const [newDate] = useState(dueDate);
  const [selectedPriority, setSelectedPriority] = useState(priority);
  const [isPriorityOpen, setIsPriorityOpen] = useState(false);
  const [assignees, setAssignees] = useState<IProjectMember[]>([]);
  const [onMouseOver, setOnMouseOver] = useState(false);
  const [refresh, setFresh] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [permision, setPermision] = useState(true);

  useEffect(() => {
    if (subscribersIds && projectMembers) {
      let assigneesData = [] as IProjectMember[];
      subscribersIds.split(',').forEach((id) => {
        const assigneeData = projectMembers.filter((member) => member.id.toString() === id.toString());
        assigneesData = assigneesData.concat(assigneeData);
      });
      setAssignees(assigneesData);
    }
  }, [projectMembers, subscribersIds, refresh, todoLists, setTodoLists, todoItem]);

  const setNewTodoItem = async (date = newDate, newPriority = selectedPriority, newAssigneesIds = subscribersIds) => {
    if (!permision) {
      return;
    }
    setAddingTodoItem(false);
    const notes = '';
    const originNotes = '';
    const newDescription = inputRef.current?.value.trim();
    const newTodoItem = {
      companyId,
      projectId,
      notes,
      originNotes,
      description: newDescription,
      priority: newPriority,
      dueDate: date,
      subscribersIds: newAssigneesIds,
    };

    if (todoItemId === 'new' && !newDescription) {
      setUpdate((prevState) => prevState + 1);
    }
    if (todoItemId === 'new' && newDescription) {
      const { status } = await createTodoItem({ ...newTodoItem, todoListId, createdUserId });
      if (status === StatusCodes.OK) {
        setUpdate((prevState) => prevState + 1);
      }
    }
    if (todoItemId !== 'new') {
      try {
        const { status } = await updateTodoItem({ ...newTodoItem, todoItemId });
        if (status === StatusCodes.OK) {
          setUpdate((prevState) => prevState + 1);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const { isCompanyManager, isProjectMember } = useAuth();
  const disabled = !isCompanyManager && !isProjectMember;
  const handleInputBlur = () => {
    if (onMouseOver) {
      return;
    }
    setNewTodoItem();
  };
  const handleTitleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (val.length > 50) {
      setErrorMsg('At most 50 characters.');
      setPermision(false);
    } else {
      setErrorMsg('');
      setPermision(true);
    }
  };

  const handleDescriptionForEnterKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setNewTodoItem();
    }
  };
  const handleCompletedStatusChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const completedStatus = event.target.checked;
    try {
      const { status } = await changeNewTodoItemCompletedStatus({ companyId, projectId, todoItemId, completedStatus });
      if (status === StatusCodes.OK) {
        todoLists.forEach(({ todoItems }, index) => {
          if (todoLists[index].id === doneListId && !todoLists[index].todoItems.includes(todoItem)) {
            const newTodoItem = { ...todoItem, completed: true };
            todoLists[index].todoItems = [newTodoItem, ...todoItems];
            const newTodoLists = [...todoLists];
            setTodoLists(newTodoLists);
          }
          if (todoLists[index].id !== doneListId && todoLists[index].todoItems.includes(todoItem)) {
            todoLists[index].todoItems = todoLists[index].todoItems.filter((item) => item !== todoItem);
            const newTodoLists = [...todoLists];
            setTodoLists(newTodoLists);
          }
        });
      } else {
        setFresh(!refresh);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = (newPriority: string) => {
    setSelectedPriority(newPriority);
    setIsPriorityOpen(!isPriorityOpen);
    setNewTodoItem(undefined, newPriority);
  };

  return (
    <CardContainer className={classes.cardContainer}>
      <div className={classes.cardTop}>
        <TextField
          helperText={errorMsg}
          disabled={disabled}
          inputRef={inputRef}
          id="fullWidth"
          defaultValue={description}
          className={classes.textFiled}
          placeholder="task name"
          onBlur={handleInputBlur}
          onKeyUp={handleDescriptionForEnterKey}
          onChange={handleTitleNameChange}
          InputProps={{ disableUnderline: true, 'aria-label': 'task description' }}
          onMouseOver={() => setOnMouseOver(true)}
          onMouseOut={() => setOnMouseOver(false)}
        />
        <Assignees
          subscribersIds={subscribersIds}
          inputRef={inputRef}
          assignees={assignees}
          projectMembers={projectMembers}
          setNewTodoItem={setNewTodoItem}
        />
      </div>
      <div className={classes.prioritySelector}>
        <PrioritySelector
          handleSelect={handleSelect}
          select={selectedPriority}
          isOpen={isPriorityOpen}
          setIsOpen={setIsPriorityOpen}
          disabled={disabled}
        />
      </div>
      <div className={classes.cardBottom}>
        <Calendar dueDate={dueDate} setNewTodoItem={setNewTodoItem} inputRef={inputRef} disabled={disabled} />
        <Checkbox
          disabled={disabled}
          defaultChecked={completed}
          icon={<CircleCheckedFilled className={classes.checkButton} />}
          checkedIcon={<CircleCheckedFilled className={classes.checkedButton} />}
          className={classes.checkbox}
          onChange={handleCompletedStatusChange}
          data-testid="checkbox"
        />
      </div>
    </CardContainer>
  );
}

export default Card;
