import React, { useCallback } from 'react';
import * as Yup from 'yup';

import * as apiUtils from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import { ErrorMessage } from 'formik';
import refreshJwt from 'src/common/functions/refreshJwt';
import notificationIcon from 'src/assets/svgs/notificationIcon.svg';
import { useParams, useHistory } from 'react-router-dom';
import useStyles from '../CreateBlankProjectPopup.style';

export const useCreateProjectForm = () => {
  interface ICreateProjectInput {
    name: string;
    description: string;
    isPrivateString: string;
    defaultView: string;
  }

  const initialValues: ICreateProjectInput = {
    name: '',
    description: '',
    isPrivateString: 'false',
    defaultView: 'List',
  };

  const history = useHistory();

  const { companyId } = useParams<{ companyId: string }>();

  const handleOnSubmit = async ({ name, description, isPrivateString, defaultView }: ICreateProjectInput) => {
    const trimedName = name.trim();

    const ifPrivate = isPrivateString === 'true';

    const ownerId = Number(localStorage.getItem('userId') || 'no ownerId here');

    const postProjectResponse = await apiUtils.createProject({ name: trimedName, ownerId, companyId: Number(companyId), description, ifPrivate, defaultView });
    if (postProjectResponse.status === StatusCodes.OK) {
      await refreshJwt();
      history.push(`/companies/${companyId}/projects/${postProjectResponse.data}`);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Project name can not be empty').max(128, 'Name cannot exceed 128 characters'),
  });

  return { initialValues, handleOnSubmit, validationSchema };
};

export const useExtraFormConf = () => {
  const classes = useStyles();
  const getErrorMsg = useCallback((name: string) => (
    <ErrorMessage name={name}>
      { (msg) => (
        <span className={classes.error}>
          <span className={classes.img}>
            <img src={notificationIcon} alt="notificationIcon" />
          </span>
          <span className={classes.errormessage}>{msg}</span>
        </span>
      )}
    </ErrorMessage>
  ), [classes.error, classes.errormessage, classes.img]);
  return { getErrorMsg };
};
